import OrderHistoryTypes from './orderhistory.types';
import { BasketRequest } from '../../model/basket/basketrequest';

const initialState = {
    basketRequest: new BasketRequest(),
    coupon: {
        name: '',
        applied: false
    },
    canEditDiscount: false
}

const orderHistoryReducer = (state: any = initialState, action: any) => {
    switch (action.type) {
        case OrderHistoryTypes.UPDATE_CANCELLATION_REQUEST:
            return {
                ...state
            }
        case OrderHistoryTypes.ORDER_DETAILS_FOR_TRACKING:
            return {
                ...state,
                orderDetailsForTracking: action.payload
            }
        case OrderHistoryTypes.ORDER_TRACKING_STATUS_LIST:
            return {
                ...state,
                orderTrackingStatusList: action.payload
            }
        case "setorderhistoryInitialState": //use this for modifying localstorage save behaviour
            return {
                ...action.payload,
            };
        default:
            return state;
    }
};

export default orderHistoryReducer;